$(document).ready(function(){

    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth();
    
    var months = [
        'January', 
        'February', 
        'March', 
        'April', 
        'May', 
        'June', 
        'July', 
        'August', 
        'September', 
        'October', 
        'November', 
        'December'
    ];
    
    function check_year(){
        var options = '<option value="">Please select</option>';
        var selected = $('#id_bereavement_died_month').val();
    
        if ($('#id_bereavement_died_year').val() === year.toString()){
            for (var i=0; i<=month; i++){
                options += '<option value="'+(i+1)+'">'+months[i]+'</option>';
            }
            $('#id_bereavement_died_month').html(options);
    
            if (selected > month + 1){
                $('#id_bereavement_died_month').val('');
            } else {
                $('#id_bereavement_died_month').val(selected);
            }
        } else {
            for (var i=0; i<=11; i++){
                options += '<option value="'+(i+1)+'">'+months[i]+'</option>';
            }
            $('#id_bereavement_died_month').html(options);
            $('#id_bereavement_died_month').val(selected);
        }
    }
    
    function check_occured_period(){
        
        var occured_period = $('#id_bereavement_occured_period');
        var selected = occured_period.val();

        if (selected == ''){
            $('.occured_period_extra').empty();
        }

        if (selected == 'under') {
            $('.occured_period_extra').load('/referral/bereavement-form/two/under/', function(){
                check_year();
                $('#id_bereavement_died_year').change(check_year);
                $('#id_bereavement_died_month').change(check_year);
            });
        } else {
            $('.occured_period_extra').html('');
        }
    }

    check_occured_period();
    $(document).on('change', '#id_bereavement_occured_period', function(){
        check_occured_period();
    });

});