$(document).ready(function(){

    $('.application_field .comment').each(function(){
        var comment = $(this);
        var application_field = comment.closest('.application_field');
        var field_wrapper = application_field.find('.field_wrapper');
        var label = application_field.find('label.form-label');

        if (field_wrapper.length > 0) {
            var html = '<div class="comment">' + comment.html() + '</div>';
            label.after(html);
            comment.remove();
        }
    });

});
