$(document).ready(function(){

    // Who You Are Toggle

    var mourner_set = [
        "mourner_over_16",
    ];

    var supporter_set = [
        "supporter_over_16",
        "supporter_over_7",
    ];

    var under_age_set = [
        "supporter_under_7",
    ]

    function check_who_you_are(){
        
        var who_you_are = $('#id_who_you_are');
        var selected = who_you_are.val();
        var role = 'none';

        if (mourner_set.indexOf(selected) >= 0){
            role = 'mourner';
        }
        
        if (supporter_set.indexOf(selected) >= 0){
            role = 'supporter';
        }

        if (under_age_set.indexOf(selected) >= 0){
            role = 'under_age';
        }

        if (role == 'none'){
            $('.who_you_are_extra').empty();
        } else if (role == 'mourner') {
            $('.who_you_are_extra').load('/referral/bereavement-form/mourner/');
        } else if (role == 'supporter') {
            $('.who_you_are_extra').load('/referral/bereavement-form/supporter/');
        } else if (role == 'under_age') {
            $('.who_you_are_extra').load('/referral/bereavement-form/under-age/');
        }
    }

    check_who_you_are();
    $(document).on('change', '#id_who_you_are', function(){
        check_who_you_are();
    });

});



