$(document).ready(function(){

    function check_bereavement_occured(){
        
        var bereavement_occured = $('#id_bereavement_occured');
        var selected = bereavement_occured.val();

        if (selected == ''){
            $('.bereavement_occured_extra').empty();
        }

        if (selected == 'yes') {
            $('.bereavement_occured_extra').load('/referral/bereavement-form/two/continue/', function(){
                var occured_period = $('#id_bereavement_occured_period');
                occured_period.trigger('change');
            });
        }
        
        if (selected == 'no') {
            $('.bereavement_occured_extra').load('/referral/bereavement-form/two/stop/');
        }
    }

    check_bereavement_occured();
    $(document).on('change', '#id_bereavement_occured', function(){
        check_bereavement_occured();
    });

});