$(document).ready(function() {

    // Deal With formsets

    function show_hide(include){
        var counter_item = include.closest('.counter_item');
        if (include.prop('checked')) {
            counter_item.addClass('counter_item--open');
        } else {
            counter_item.removeClass('counter_item--open');
        }
    }

    if ($('.apply-form').length > 0){

        $('.ed-include, .pe-include, .rf-include').each(function(){
            var include = $(this);
            show_hide(include);
        });

        $('.ed-include, .pe-include, .rf-include').change(function(){
            var include = $(this);
            show_hide(include);
        });

        $('.counter_cancel button').click(function(){
            var cancel = $(this);
            var counter_item = cancel.closest('.counter_item');
            var include = counter_item.find('.ed-include, .pe-include, .rf-include');
            include.prop('checked', false);
            show_hide(include);
        });

    }
});
