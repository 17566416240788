$(document).ready(function(){

    function guide_price(){
        var how_many_numbers = $('#id_how_many_numbers').val();
        //  var how_many_weeks = $('#id_how_many_weeks').val();
        if (how_many_numbers == ''){
            $('.play-guide').text('');
        } else {
            var total = parseInt(how_many_numbers) * 5;
            $('.play-guide').text('Total: £' + total);
        }
    }

    guide_price();
    $(document).on('change', '#id_how_many_numbers, #id_how_many_weeks, #id_how_often_play', function(){
        guide_price();
    });

});