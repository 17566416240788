// Function to build dd/yyyy selector

function ddyyyy(){

    $('.ddyyyy').each(function(){
        var field = $(this);

        if (!field.hasClass('ddyyyy-wrapper')){

            var input = field.find('input');
            var name = input.attr('name');
            var name_mm = name + '-mm';
            var name_yyyy = name + '-yyyy';

            var html = '';
            html += '<div class="ddyyyy-wrapper" style="display:flex;">';
            html += '<select class="form-select" name="' + name_mm + '" placeholder="MM">';
            html += '<option value="" disabled selected value>MM</option>';
            for (var i = 1; i <= 12; i++) {
                var value = ('0' + i).slice(-2);
                html += '<option value="' + value + '">' + value + '</option>';
            }
            html += '</select>'
            html += '&nbsp;/&nbsp;';
            html += '<input type="number" class="form-control" name="' + name_yyyy + '" placeholder="YYYY">';
            html += '</div>';

            input.css('display', 'none');
            input.after(html);

            $('select[name="' + name_mm + '"], input[name="' + name_yyyy + '"]').on('blur', function(){
                var value = '';
                if ($('input[name="' + name_yyyy + '"]').val() != ''){
                    //value = '01/' + $('select[name="' + name_mm + '"]').val() + '/' + $('input[name="' + name_yyyy + '"]').val();
                    value = $('input[name="' + name_yyyy + '"]').val() + '-' + $('select[name="' + name_mm + '"]').val() + '-01';
                }
                input.attr('value', value);
                input.val(value);
            })
        }
    });
}

$(document).ready(function() {

    // Paint selectors to be dd/yyyy'ed
    $('.application_field.field-ce_date_joined').addClass('ddyyyy');
    $('.application_field.field-date_from').addClass('ddyyyy');
    $('.application_field.field-date_to').addClass('ddyyyy');

    // Build dd/yyyy selectors
    ddyyyy();

});