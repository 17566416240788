$(document).ready(function(){
    $('.mobile-toggle').click(function(){
        $('nav .links').toggleClass('active');
    });
});

$(document).on('click', '.dropdown-toggler', function(e){
    e.preventDefault();
    var item = $(this).closest('li');
    if (item.hasClass('m-active')){
        // console.log('HAS');
        item.removeClass('m-active');
        item.removeClass('active');
    } else {
        // console.log('hAS NOT');
        item.addClass('m-active');
        item.addClass('active');
    }
});

$(document).on('mouseenter', 'nav li', function(){
    // console.log('HERE');
    $(this).addClass('active');
});

$(document).on('mouseleave', 'nav li', function(){
    // console.log('HERE 2');
    $(this).removeClass('active');
    $(this).removeClass('m-active');
}); 


function scroll_nav(){
    var scroll = $(window).scrollTop();
    var header = $('#header');
    if (header.hasClass('fixed_header')){
        if (scroll < 1){
            header.removeClass('fixed_header');
        }
    } else {
        if (scroll >= 1){
            header.addClass('fixed_header');
        }
    }
}

scroll_nav();
$(window).on('scroll', function(){
    scroll_nav();
});


$(document).on('click', '#open_nav', function(e){
    e.preventDefault();
    $('#header').addClass('nav_open');
});

$(document).on('click', '#close_nav', function(e){
    e.preventDefault();
    $('#header').removeClass('nav_open');
});

$(document).on('click', '.sidebar .open_close', function(e){
    e.preventDefault()
    $('.sidebar ul').toggleClass('sidebar_open');
    $('.sidebar .open_close .fa-caret-down').toggleClass('d-none');
    $('.sidebar .open_close .fa-caret-up').toggleClass('d-none');
});


$(document).on('click', '#donate .donate_amount', function(e){
    e.preventDefault();
    var amount = $(this).attr('data-amount');
    $('.donate_amount').addClass('btn-primary');
    $('.donate_amount').removeClass('btn-quarternary');
    $(this).removeClass('btn-primary');
    $(this).addClass('btn-quarternary');
    if( amount == 'other'){
        $('.donate_amount_other').slideDown();
        var other_val = $('#donate_other_box').val()
        $('#id_donate_amount').val(other_val);
        var amount_ga = parseFloat(other_val) * 1.25;
        if(amount_ga){
            $('#donate .gift_aid_value').html(amount_ga.toFixed(2));
        }
    } else {
        $('.donate_amount_other').slideUp();
        $('#id_donate_amount').val(amount);
        var amount_ga = parseFloat(amount) * 1.25;
        $('#donate .gift_aid_value').html(amount_ga.toFixed(2));
    }
});

$(document).on('change', '#donate #donate_other_box', function(e){
    e.preventDefault();
    var other_val = $(this).val();
    $('#id_donate_amount').val(other_val)
    var amount_ga = parseFloat(other_val) * 1.25;
    $('#donate .gift_aid_value').html(amount_ga.toFixed(2));
});

$(document).on('click', '#minicart .close', function(e){
    var minicart = $(this).closest('#minicart');
    minicart.slideUp();
});